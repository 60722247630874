import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Backdrop,
  CircularProgress
} from "@mui/material";
import usersService from "../../services/usersService";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { IconButton } from "@mui/material";
import { Visibility as EyeIcon } from "@mui/icons-material";
import MessageViewDialog from "./components/MessageViewDialog";
import AlertSnackbar from "../../components/AlertSnackbar";

const CopyDataPage = () => {
  const [userList, setUserList] = useState([]);
  const [fromUser, setFromUser] = useState(null);
  const [featureName, setFeatureName] = useState("");
  const [toUser, setToUser] = useState(null);
  const [dataGridRows, setDataGridRows] = useState([]);
  const [fromUserData, setFromUserData] = useState([]);
  const [toUserData, setToUserData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [messageDialog, setMessageDialog] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const loadingRef = useRef(0);
  const featureNames = [
    { id: 1, label: "Calendar", value: "calendar" },
    { id: 2, label: "Bulk Template", value: "bulkTemplate" },
    { id: 3, label: "Quick Template", value: "quickTemplate" },
  ];

  const getColumnsForFeature = () => {
    switch (featureName) {
      case "calendar":
        return [
          { field: "name", headerName: "Event Name", width: 200 },
          {
            field: "color",
            headerName: "Color",
            width: 100,
            filterable: false,
            renderCell: (params) => (
                <Box className="flex items-center w-[100%] h-[100%]">
                    <Box
                        sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: params.value,
                        borderRadius: 50
                        }}
                    />
                </Box>
            ),
          },
          {
            field: "content",
            headerName: "Content",
            width: 180,
            renderCell: (params) => (
              <Box
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                dangerouslySetInnerHTML={{ __html: params.value }}
              />
            ),
          },
          { field: "postLink", headerName: "Post Link", width: 300 },
          { field: "imageURL", headerName: "Image Url", width: 300 },
          {
            field: "startDate",
            headerName: "Event Date",
            width: 200,
            valueGetter: (params) => moment(params.value).format("MMM DD, YYYY"),
            renderCell: (params) => {
              return moment(params.value).format("MMM DD, YYYY");
            },
            valueGetter: (value) => {
              return moment(value).format("MMM DD, YYYY");
            }
          },
        ];
      case "bulkTemplate":
        return [
          { field: "title", headerName: "Title", width: 200 },
          {
            field: "messages",
            headerName: "Messages",
            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
              return (
                <IconButton onClick={() => {setMessages(params.value); setMessageDialog(true)}}>
                  <EyeIcon />
                </IconButton>
              );
            },
          },
          {
            field: "createdAt",
            headerName: "Created At",
            width: 200,
            valueGetter: (params) => moment(params.value).format("MMM DD, YYYY"),
            renderCell: (params) => {
              return moment(params.value).format("MMM DD, YYYY");
            },
            valueGetter: (value) => {
              return moment(value).format("MMM DD, YYYY");
            }
          },
          { field: "error", headerName: "Remarks", width: 400 },
        ];
      case "quickTemplate":
        return [
          { field: "templateName", headerName: "Template Name", width: 200 },
          { field: "trigger", headerName: "Trigger", width: 100 },
          { field: "triggerText", headerName: "Trigger Text", width: 180 },
          {
            field: "messages",
            headerName: "Messages",
            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
              return (
                <IconButton onClick={() => {setMessages(params.value); setMessageDialog(true)}}>
                  <EyeIcon />
                </IconButton>
              );
            },
          },
          {
            field: "createdAt",
            headerName: "Created At",
            width: 200,
            renderCell: (params) => {
              return moment(params.value).format("MMM DD, YYYY");
            },
            valueGetter: (value) => {
              return moment(value).format("MMM DD, YYYY");
            }
          },
          { field: "error", headerName: "Remarks", width: 400 },
        ];
      default:
        return [];
    }
  };

  const getUniqueIdForFeature = (row) => {
    switch (featureName) {
      case "calendar":
        return row.calendarEventId;
      case "bulkTemplate":
        return row.messageTemplateId;
      case "quickTemplate":
        return row.templateId;
      default:
        return [];
    }
  };

  const fetchUserList = async () => {
    try {
      const resp = await usersService.getAllUserDetails({status: 'active', orderBy: 'name'});
      const activeUsers = resp?.data?.data?.users?.filter(user=> user.facebookUserName) 
      setUserList(activeUsers || []);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserData = async (side) => {
    try {
      const user = side=="from" ? fromUser : toUser;
      const resp = await usersService.getUserFeatureData({
        userId: user.userId,
        featureName,
        facebookUserId: user.facebookUserId
      });
      // console.log(resp.data);
      if(side=="from"){
        setFromUserData(resp?.data?.data || [])
      } else {
        setToUserData(resp?.data?.data || [])
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopyData = async() => {
    setIsLoading(true);
    try {
      const payload = { data: selectedRows, toUser, featureName };
      console.log(payload);
      const resp = await usersService.copyFeatureData(payload);
      // console.log(resp);
      setSnackbar({
        open: true,
        message: "Copy Completed Successfully.",
        severity: "success",
      });
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        message: error?.response?.data?.message || "Something went wrong while copying.",
        severity: "error",
      });
    } finally {
      fetchUserData("to");
      setDisplayData();
      setIsLoading(false);
    }
  };

  const setDisplayData = () => {
    // console.log("display data called", fromUserData, toUserData, featureName)
    switch (featureName) {
      case "calendar":
        setDataGridRows(fromUserData);
        break;
      case "bulkTemplate":
        const updatedbulkTemplateData = fromUserData.map((fromItem) => {
          const hasSameTitle = toUserData.some(
            (toItem) => toItem.title === fromItem.title
          );

          return hasSameTitle
            ? { ...fromItem, error: "Same template name exists for this user" }
            : fromItem;
        });
        setDataGridRows(updatedbulkTemplateData);
        break;
      case "quickTemplate":
        const updatedquickTemplateData = fromUserData.map((fromItem) => {
          const hasSameName = toUserData.some(
            (toItem) => toItem.templateName === fromItem.templateName
          );

          return hasSameName
            ? { ...fromItem, error: "Same template name exists for this user" }
            : fromItem;
        });
        setDataGridRows(updatedquickTemplateData);
        break;
      default:
        return setDataGridRows([]);
    }
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    if (featureName && fromUser) {
      loadingRef.current++;
      fetchUserData("from").finally(() => loadingRef.current--);
    }
  }, [fromUser]);

  useEffect(() => {
    if (featureName && toUser) {
      loadingRef.current++;
      fetchUserData("to").finally(() => loadingRef.current--);
    }
  }, [toUser]);

  useEffect(() => {
    const promises = [];
    if (toUser) {
      loadingRef.current++;
      promises.push(fetchUserData("to").finally(() => loadingRef.current--));
    }
    if (fromUser) {
      loadingRef.current++;
      promises.push(fetchUserData("from").finally(() => loadingRef.current--));
    }
    Promise.all(promises);
  }, [featureName]);

  useEffect(() => {
    if (loadingRef.current === 0) {
      setDisplayData();
    }
  }, [fromUserData, toUserData]);

  useEffect(() => {
    fetchUserList();
  }, []);

  return (
    <>
        {messageDialog && <MessageViewDialog {... {messageDialog, setMessageDialog, messages}}></MessageViewDialog>}
        <Box>
            <Typography variant="h1" gutterBottom>
                Copy Data
            </Typography>
            <Divider sx={{ marginBottom: "1em" }} />
            <Grid container spacing={2}>
                {/* Select From User */}
                <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="from-user-label">From User</InputLabel>
                    <Select
                    labelId="from-user-label"
                    value={fromUser ? JSON.stringify(fromUser) : ""}
                    onChange={(e) => setFromUser(JSON.parse(e.target.value))}
                    label="From User"
                    >
                    {userList.map((user) => (
                      <MenuItem
                        key={`${user.userId}-${user.facebookUserId}`}
                        value={JSON.stringify({ userId: user.userId, facebookUserId: user.facebookUserId })}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <span
                            style={{
                              flex: 6,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {`${user.firstName} ${user.lastName ?? ''} - ${user.email}`}
                          </span>
                          <span
                            style={{
                              flex: 4,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              textAlign: "right",
                            }}
                          >
                            {`${user.facebookUserName}`}
                          </span>
                        </Box>
                      </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </Grid>

                {/* Select Feature Name */}
                <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="feature-name-label">Feature Name</InputLabel>
                    <Select
                    labelId="feature-name-label"
                    value={featureName}
                    onChange={(e) => setFeatureName(e.target.value)}
                    label="Feature Name"
                    >
                    {featureNames.map((feature) => (
                        <MenuItem key={feature.id} value={feature.value}>
                        {feature.label}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </Grid>

                {/* Select To User */}
                <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel id="to-user-label">To User</InputLabel>
                    <Select
                    labelId="to-user-label"
                    value={toUser ? JSON.stringify(toUser) : ""}
                    onChange={(e) => setToUser(JSON.parse(e.target.value))}
                    label="To User"
                    >
                    {userList.map((user) => (
                      <MenuItem
                        key={`${user.userId}-${user.facebookUserId}`}
                        value={JSON.stringify({ userId: user.userId, facebookUserId: user.facebookUserId })}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <span
                            style={{
                              flex: 6,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {`${user.firstName} ${user.lastName ?? ''} - ${user.email}`}
                          </span>
                          <span
                            style={{
                              flex: 4,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              textAlign: "right",
                            }}
                          >
                            {`${user.facebookUserName}`}
                          </span>
                        </Box>
                      </MenuItem>

                    ))}
                    </Select>
                </FormControl>
                </Grid>
            </Grid>
            <Box sx={{ marginTop: "2em", textAlign: "center" }}>
                <Button
                variant="contained"
                color="primary"
                onClick={handleCopyData}
                disabled={!fromUser || !featureName || !toUser || !selectedRows.length || (fromUser?.userId==toUser?.userId && fromUser?.facebookUserId==toUser?.facebookUserId)} // Disable button until all fields are selected
                sx={{
                    fontSize: "1.1rem",
                    padding: "0.5rem 2rem",
                }}
                >
                {`Copy Selected Data - ${selectedRows.length}`}
                </Button>
            </Box>
            {featureName && fromUser && (
                <Box sx={{ marginTop: "2em", width: "100%" }}>
                <DataGrid
                    rows={dataGridRows}
                    columns={getColumnsForFeature()}
                    getRowId={(row) => getUniqueIdForFeature(row)}
                    checkboxSelection={toUser !== null}
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 10,
                        },
                    },
                    }}
                    pageSizeOptions={[5, 10, 20]}
                    disableSelectionOnClick
                    isRowSelectable={(params) => !params.row.error}
                />
                </Box>
            )}
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <AlertSnackbar
          open={snackbar.open}
          handleClose={handleCloseSnackbar}
          message={snackbar.message}
          severity={snackbar.severity}
        />
    </>
  );
};

export default CopyDataPage;
