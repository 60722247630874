import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import MUITableComponent from "../../components/MUITableComponent";
const ClientListDialog = ({ openDialog, handleCloseDialog, selectedRow, userData }) => {
  const navigate= useNavigate();
  const handleClickUserSpcificFeaturePage=(selectedUser)=>{
    navigate(`/user_reports/${selectedUser.userId}`,{state:{email:selectedUser.email,name:selectedUser.username,userId:selectedUser.userId}});
    handleCloseDialog();
  }
  const columns = [
    {
      id: "username",
      label: "Username",
      sortable: true,
      clickable: true,
      styling:{color:"#5656f1",cursor:'pointer' ,textDecoration:'underline'}
    },
    {
      id: "email",
      label: "Email",
      sortable: true,
      clickable: false,
    },
    {
      id: "usageCount",
      label: "Usage Count",
      sortable: true,
      clickable: false,
    },
    {
      id: "lastUsedDate",
      label: "Last Used Date",
      sortable: false,
      clickable: false,
    },
  ];
  return (<>
    <Dialog open={openDialog} onClose={handleCloseDialog} sx={{ '& .MuiDialog-paper': { width: '772px', maxWidth: '100%' } }}>
      <DialogTitle>{selectedRow?.featureName} {selectedRow?.featureName==="Track Prospects"?"Label" : "Client Used"} Count Details
        <Typography></Typography>
        <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
       
      <DialogContent sx={{padding:"20px 12px"}}>
        <Box>
        <MUITableComponent
          columns={columns}
          tableData={userData}
          handleCellClick={handleClickUserSpcificFeaturePage}
        />
        </Box>
      </DialogContent>
    </Dialog></>)

}
export default ClientListDialog;