import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  TextField,
  Button,
  Chip,
  Divider,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import courseService from "../../services/courseService";
import previewCourseDefaultImage from "../../asset/images/preview-course-default.jpeg";
import {
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AlertSnackbar from "../../components/AlertSnackbar";

const PriceRibbon = ({ price }) => (
  <Box
    sx={{
      position: "absolute",
      top: 16,
      right: -36,
      transform: "rotate(45deg)",
      backgroundColor: "primary.main",
      color: "white",
      padding: "6px 40px",
      fontWeight: "bold",
      zIndex: 1,
      boxShadow: "0 3px 10px rgba(0,0,0,0.3)", // Added shadow
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "100%",
        width: 0,
        height: 0,
      },
      "&::before": {
        left: 0,
        borderRight: "4px solid transparent",
        borderBottom: "4px solid transparent",
        borderLeft: "4px solid rgba(0,0,0,0.2)", // Shadow effect
      },
      "&::after": {
        right: 0,
        borderLeft: "4px solid transparent",
        borderBottom: "4px solid transparent",
        borderRight: "4px solid rgba(0,0,0,0.2)", // Shadow effect
      },
    }}
  >
    ${price}
  </Box>
);

const ManageCourses = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleCourses, setVisibleCourses] = useState(9);
  const [courseList, setCourseList] = useState([]);
  const [publishedChecked, setPublishedChecked] = useState(true);
  const [draftChecked, setDraftChecked] = useState(true);
  const [archivedChecked, setArchivedChecked] = useState(false);
  const coursesPerLoad = 9;
  const [selectedCourse, setSelectedCourse] = useState(null)
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  let filteredCourses = courseList.filter((course) =>
    course.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const statusFilter = [];
  if (publishedChecked) {
    statusFilter.push("published");
  }
  if (draftChecked) {
    statusFilter.push("draft");
  }
  if (archivedChecked) {
    statusFilter.push("archived");
  }

  if (statusFilter.length > 0) {
    filteredCourses = filteredCourses.filter((course) =>
      statusFilter.includes(course.status)
    );
  }

  const currentCourses = filteredCourses.slice(0, visibleCourses);

  const handleLoadMore = () => {
    setVisibleCourses((prevVisible) => prevVisible + coursesPerLoad);
  };

  const handleCardClick = (courseId) => {
    navigate(`/courses/edit/${courseId}`);
  };

  const fetchCourses = async () => {
    try {
      const resp = await courseService.getAllCourse();
      console.log("Get all course: ", resp);
      setCourseList(resp?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event,course) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedCourse(course)
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleCopyLink = (event) => {
    event.stopPropagation();
    let courseLink = '';
    if(window.location.origin.includes("localhost")){
      courseLink = `http://localhost:3000`
    } else if(window.location.origin.includes("test.yunapro")){
      courseLink = `https://test.yunapro.com`
    } else {
      courseLink = `https://app.yunapro.com`
    }
    courseLink += `/yunapro-university?selectedCourse=${selectedCourse.courseId}`;
    navigator.clipboard.writeText(courseLink);
    handleMenuClose(event);
    setSnackbar({
      open: true,
      message: "link copied to clipboard",
      severity: "success",
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };
  
  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h1" sx={{ flexGrow: 1 }}>
          Manage Courses
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate("/courses/create")}
          sx={{ ml: 2 }}
        >
          Create New Course
        </Button>
      </Box>
      <Divider sx={{ marginBottom: "1em" }} />
      <Box
        sx={{
          my: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          placeholder="Search courses"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
          sx={{ flexGrow: 1 }}
        />
        <FormGroup
          sx={{
            ml: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={publishedChecked}
                onChange={(e) => setPublishedChecked(e.target.checked)}
                color="success"
              />
            }
            label="Published"
            sx={{ mr: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={draftChecked}
                onChange={(e) => setDraftChecked(e.target.checked)}
                color="warning"
              />
            }
            label="Draft"
            sx={{ mr: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={archivedChecked}
                onChange={(e) => setArchivedChecked(e.target.checked)}
                color="default"
              />
            }
            label="Archived"
            sx={{ mr: 2 }}
          />
        </FormGroup>
      </Box>
      <Grid container spacing={4}>
        {currentCourses.map((course) => (
          <Grid item key={course.courseId} xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "12px",
                cursor: "pointer",
                "&:hover": {
                  boxShadow: 3,
                },
                position: "relative",
                overflow: "hidden",
              }}
              variant="outlined"
              onClick={() => handleCardClick(course.courseId)}
            >
              {course.price > 0 && <PriceRibbon price={course.price} />}
              <CardMedia
                component="img"
                image={
                  course.thumbnailUrl
                    ? course.thumbnailUrl
                    : previewCourseDefaultImage
                }
                alt=""
                sx={{ height: "225px" }}
              />
              <CardContent
                sx={{
                  flexGrow: 1,
                  position: "relative",
                  padding: "1rem !important",
                }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  {course.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: course.description }}
                  />
                </Typography>
                <Chip
                  label={course.status}
                  color={
                    course.status === "published"
                      ? "success"
                      : course.status === "archived"
                      ? "default"
                      : "warning"
                  }
                  size="small"
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuOpen(e, course);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={(e)=>handleCopyLink(e)}>Copy Link</MenuItem>
                </Menu>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {visibleCourses < filteredCourses.length && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button variant="contained" color="primary" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      )}
      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </div>
  );
};

export default ManageCourses;
