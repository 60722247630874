import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const MessageViewDialog = ({ messageDialog, setMessageDialog, messages }) => {
  return (
    <Dialog
      open={messageDialog}
      maxWidth="md"
      fullWidth={true}
      disableEnforceFocus
    >
      <DialogTitle className="flex justify-between items-center">
        <Typography
          variant="subtitle1"
          className="text-grey-A1000"
          fontWeight={600}
          fontSize={16}
        >
          Messages
        </Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={() => {
          setMessageDialog(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {messages.length > 0 ? (
          messages.map((message, index) => (
            <Box key={index} className="mb-4">
              <p>{message.body}</p>
              <hr />
            </Box>
          ))
        ) : (
          <p>No messages available</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MessageViewDialog;
