import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import MUITableComponent from "../../components/MUITableComponent";
import { useEffect, useState } from "react";
import ClientListDialog from "./ClientListDialog";
import reportsService from "../../services/reportsService/reportsService";
import InfoIcon from "@mui/icons-material/Info";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datepicker.css";
import {
  calculateStartDate,
  featureNameMapping,
  reverseFeatureNameMapping,
} from "../../utils/helper";
import { formatDateToLongString } from "../../utils/constant";

const FeatureReportsPage = () => {
  const [timeRange, setTimeRange] = useState("Month");
  const [startDate, setStartDate] = useState(
    calculateStartDate(timeRange.toLowerCase())
  );
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const renderTotalFeatureUsageCell = (row) => {
    const statusDetails = Object.entries(row.statusCounts)
      .map(([status, count]) => `${status}: ${count}`)
      .join(", ");
      console.log("statusDetails",statusDetails)
    if(statusDetails){

      return (
        <Tooltip title={`Status Details - ${statusDetails}`}>
          {row.totalFeatureUsage}
        </Tooltip>
    );
    } else{
      return (
        <>{row.totalFeatureUsage}</>
      )
    }
  };

  const columns = [
    { id: "featureName", label: "Feature Name", sortable: true, width: "30%" },
    {
      id: "totalFeatureUsage",
      label: (
        <>
          Total Feature Usage{" "}
          <Tooltip title={`Hover to see the details status`}>
            <IconButton size="small" sx={{ ml: 1 }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
      sortable: true,
      width: "25%",
      sortFunction:(a,b)=>a.totalFeatureUsage.props.children-b.totalFeatureUsage.props.children
    },
    {
      id: "lastUsageTime",
      label: "Last Used Date",
      sortable: true,
      width: "20%",
    },
    {
      id: "clientUsedCount",
      label: "Client Used Count",
      sortable: true,
      width: "20%",
      clickable: true,
      styling: {color:"#5656f1"}
    },
    { id: "avgResult", label: "Average Result", sortable: false, width: "15%" },
  ];

  const [data, setData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userData, setUserData] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let queryParam = {};
      if (timeRange !== "All Time") {
        queryParam = {
          startDate: startDate,
          endDate: endDate,
        };
      }
      const response = await reportsService.getPanelReports(queryParam);
      const formattedData = formatDataForTable(response.data.data);
      setData(formattedData);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const formatDataForTable = (data) => {
    return data.map((item) => {
      const totalAverageResult = Object.entries(item.avgResult)
      .map(([key, value]) => `${key!=="sentCount"?key:"Action Taken"}: ${value}`)
      .join(", "); 
      return {
        featureName: featureNameMapping[item.featureName] || item.featureName,
        totalFeatureUsage: renderTotalFeatureUsageCell(item),
        clientUsedCount: item.noOfClientUsedCount || 0,
        avgResult: totalAverageResult,
        lastUsageTime: formatDateToLongString(item.lastUsageTime)
      };
    });
  };
  const handleTimeRangeChange = (newRange) => {
    if (newRange && newRange !== timeRange) {
      if (newRange !== "custom") {
        setStartDate(calculateStartDate(newRange.toLowerCase()));
        setEndDate(new Date());
      } 
      if(newRange==="custom" || newRange==="All Time") {
        setStartDate(null);
        setEndDate(null);
      }
      setTimeRange(newRange);
    }
  };

  const handleClientCountClick = async (row) => {
    if(row.clientUsedCount > 0){
      setIsLoading(true);
      setSelectedRow(row);
      let queryParams = {
        featureName: reverseFeatureNameMapping[row.featureName],
      };
      if (timeRange !== "All Time") {
        queryParams.startDate = startDate;
        queryParams.endDate = endDate;
      }
      const users = await reportsService.getUserDetails(queryParams);
      setUserData(generateUserData(users.data.data));
      setOpenDialog(true);
      setIsLoading(false);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRow(null);
  };
  const generateUserData = (userData) => {
    let users = [];
    for (let i = 0; i < userData.length; i++) {
      users.push({
        username: `${userData[i].firstName} ${userData[i].lastName}`,
        email: `${userData[i].email}`,
        usageCount: userData[i].featureUsageCount,
        userId: userData[i].userId,
        lastUsedDate: formatDateToLongString(userData[i].lastUsageTime)
      });
    }
    return users;
  };

  const handleCustomTimeRangeChange = (dates) => {
    let [start, end] = dates;
    if (end) {
      end = new Date(end.setHours(23, 59, 59, 59));
    }
    setStartDate(start);
    setEndDate(end);
  };
  useEffect(() => {
    if (startDate && endDate && timeRange === "custom") {
      fetchData();
    }
  }, [startDate, endDate, timeRange]);
  useEffect(() => {
    if (timeRange !== "custom") {
      fetchData();
    }
  }, [timeRange]);
  return (
    <div>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h1" gutterBottom>
          Feature Reports
        </Typography>
      </Box>
      <Divider sx={{ marginBottom: "1em" }} />
      <Box display="flex" sx={{ justifyContent: "space-between" }}>
        <Box display="flex" gap={2} justifyContent="between">
          <ButtonGroup
            sx={{ marginBottom: "1em" }}
            variant="outlined"
            color="primary"
          >
            <Button
              onClick={() => handleTimeRangeChange("Month")}
              variant={timeRange === "Month" ? "contained" : "outlined"}
            >
              Month
            </Button>
            <Button
              onClick={() => handleTimeRangeChange("Week")}
              variant={timeRange === "Week" ? "contained" : "outlined"}
            >
              Week
            </Button>
            <Button
              onClick={() => handleTimeRangeChange("Year")}
              variant={timeRange === "Year" ? "contained" : "outlined"}
            >
              Year
            </Button>
            <Button
              onClick={() => handleTimeRangeChange("All Time")}
              variant={timeRange === "All Time" ? "contained" : "outlined"}
            >
              All Time
            </Button>
            <Button
              onClick={() => handleTimeRangeChange("custom")}
              variant={timeRange === "custom" ? "contained" : "outlined"}
            >
              Custom
            </Button>
          </ButtonGroup>
          <Box sx={{ height: "27px" }}>
            {timeRange === "custom" && (
              <ReactDatePicker
                className=" w-full bg-white border border-gray-300 rounded-md shadow-sm p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                selected={startDate}
                onChange={(dates) => {
                  handleCustomTimeRangeChange(dates);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                isClearable
                placeholderText="Select Range"
                popperClassName="custom-datepicker-popup"
              />
            )}
          </Box>
        </Box>
        {startDate && endDate && (
          <Typography>
            {formatDateToLongString(startDate)} -{" "}
            {formatDateToLongString(endDate)}
          </Typography>
        )}
      </Box>
      {data && (
        <MUITableComponent
          tableData={data}
          columns={columns}
          handleCellClick={handleClientCountClick}
        />
      )}
      {isLoading && (<Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>)}
      <ClientListDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        selectedRow={selectedRow}
        userData={userData}
      ></ClientListDialog>
    </div>
  );
};
export default FeatureReportsPage;
