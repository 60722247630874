import axios from "axios";
import { jwtDecode } from "jwt-decode";
import userServiceConfig from "./userServiceConfig";

class UserService {
  constructor() {
    this.init();
  }

  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err?.response?.status === 401 && err.config) {
          this.logout();
        }
        return Promise.reject(err);
      }
    );
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      console.log('No access token found');
      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      console.log('Auto login successful');
    } else {
      this.setSession(null);
      console.log('Auto logout: access_token expired');
    }
  };

  signInWithEmailAndPassword = (email, password) => {
    return axios.post(userServiceConfig.adminSignIn, { email, password })
      .then((response) => {
        if (response.data.data.accessToken) {
          this.setSession(response.data.data.accessToken);
          return this.fetchUserDetailByToken();
        } else {
          throw new Error(response.data.data.error);
        }
      });
  };

  fetchUserDetailByToken = () => {
    return axios.get(userServiceConfig.userDetail)
      .then((response) => {
        if (response.data.data) {
          return response.data.data;
        } else {
          this.logout();
          throw new Error('Failed to login with token.');
        }
      });
  };

  logout = () => {
    this.setSession(null);
  };

  setSession(accessToken) {
    if (accessToken) {
      localStorage.setItem("jwt_access_token", accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
    }
  }

  isAuthTokenValid(accessToken) {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  }

  getAccessToken() {
    return localStorage.getItem("jwt_access_token");
  }

  isAuthenticated() {
    const token = this.getAccessToken();
    if (!token) {
      return false;
    }
    return this.isAuthTokenValid(token);
  }

  getAllUserDetails = (params={}) => axios.get(`${userServiceConfig.getAllUserDetails}`,{params});
  updateUserDetail = (body, id) => axios.patch(`/update-user/detail/${id}`, body);
  // {
  //     "facebookUserName":"xFlNlSHER",
  //     "status":"active",
  //     "email":"arvind@yopmail.com",
  //     "firstName":"Arvind",
  //     "lastName":"Yadav"
  // }
  updateUserPassword = (body, id) => axios.patch(`/update-user/password/${id}`, body);
  // {
  //   "password":"arvind@2002"
  // }
  createUser = (body) => axios.post(`/create-user`, body);
  // {
  //     "firstName":"john",
  //     "email":"test1@gmail.com"
  //     lastname optional
  // }
  createFacebookUser = (body) => axios.post(`/create-facebook-user`, body);
  // {
  //   "email":"test1@gmail.com",
  //   "facebookUserName":"test1",
  //   "password":"test1"
  // }
  getUserDataById = (id=null) => axios.get(`/get-user/${id}`);

  sendBulkMail = (payload) =>axios.post(`/send-bulk-mail`, payload);

  fetchSurveyReportData = (params) =>axios.get(`/survey-report?taskName=${params.taskName}`)
  
  getBatchData = ()=>axios.get('/get-all-email-batch');

  getUsersStatus = (params) =>axios.get(`/get-email-batch-data?batchId=${params.batchId}&subjectTemplate=${params.subjectTemplate}`)

  getUserFeatureData = (params) =>axios.get(`/feature-specific-user-data?userId=${params.userId}&featureName=${params.featureName}`)
  copyFeatureData = (payload) => axios.post(`/copy-feature-data`, payload);
} 

const instance = new UserService();

export default instance;